import {
  UserManager,
  Log,
  User,
  WebStorageStateStore,
  SigninRequest,
} from "oidc-client";

export interface IIdentityConfig {
  authority: string;
  client_id: string;
  redirect_uri: string;
  post_logout_redirect_uri: string;
  response_type: string;
  response_mode: string;
  scope: string;
  automaticSilentRenew: boolean;
  loadUserInfo: boolean;
}

export interface IAuthService {
  signinRedirectCallback(): void;
  logout(): void;
  signoutRedirectCallback(): void;
  isAuthenticated(): boolean;
  signinRedirect(): void;
  signinSilentCallback(): void;
  createSigninRequest(): void;
  getUser(): Promise<User | null>;
}
export class AuthService implements IAuthService {
  private _userManager: UserManager;

  constructor(config: IIdentityConfig) {
    this._userManager = new UserManager({
      ...config,
      userStore: new WebStorageStateStore({
        store: window.sessionStorage as any,
      }),
    });

    Log.logger = console;
    Log.level = Log.DEBUG;
    console.log("ctor of AuthService");
    this._userManager.events.addUserLoaded((user: User) => {
      console.log("user loaded");
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this._userManager.events.addSilentRenewError((e: Error) => {
      console.log("silent renew error", e.message);
      this.signinRedirect();
    });

    this._userManager.events.addAccessTokenExpired(() => {
      console.log("token expired, signin-silent");
      this.signinSilent();
    });
  }

  signinRedirectCallback = (): void => {
    this._userManager.signinRedirectCallback();
    // .then(() => {
    //   //window.location.replace("http://localhost:61933");
    //   "";
    // });
  };

  getUser = async (): Promise<User | null> => {
    const user = await this._userManager.getUser();
    //if (!user) {
    //  return await this.UserManager.signinRedirectCallback();
    //}
    return user;
  };

  parseJwt = (token: string): any => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (): void => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this._userManager.signinRedirect({});
  };

  navigateToScreen = (): void => {
    const redir = localStorage.getItem("redirectUri");
    if (redir) window.location.replace(redir);
    else window.location.replace("/");
  };

  isAuthenticated = (): boolean => {
    const value = sessionStorage.getItem(
      `oidc.user:${this._userManager.settings.authority}:${this._userManager.settings.client_id}`
    );
    let oidcStorage: any;
    if (value == null) {
      oidcStorage = null;
    } else {
      oidcStorage = JSON.parse(value);
    }

    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = (): void => {
    this._userManager
      .signinSilent()
      .then((user: User) => {
        console.log("signed in", user);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  signinSilentCallback = (): void => {
    this._userManager.signinSilentCallback();
  };

  createSigninRequest = (): Promise<SigninRequest> => {
    return this._userManager.createSigninRequest();
  };

  logout = (): void => {
    this._userManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this._userManager.clearStaleState();
  };

  signoutRedirectCallback = (): void => {
    this._userManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace("/");
    });
    this._userManager.clearStaleState();
  };
}
