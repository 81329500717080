import React, { ReactElement, useMemo, Fragment, useState } from "react";
import { Table, Spinner, Pagination, Container } from "react-bootstrap";
import {
  useTable,
  Column,
  usePagination,
  Cell,
  Row,
  HeaderGroup,
  useSortBy,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  FilterProps,
  SortingRule,
  Filters,
  useRowSelect,
  PluginHook,
  HeaderProps,
  TableToggleAllRowsSelectedProps,
  ColumnInstance,
  CellProps,
  TableOptions,
  IdType,
} from "react-table";

import classNames from "classnames/bind";

import {
  BsArrowDown,
  BsArrowUp,
  BsArrowDownUp,
  BsSearch,
  BsFunnelFill,
} from "react-icons/bs";

import styles from "./DataTable.module.scss";

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

interface Props<T extends object> {
  data?: Array<T>;
  isLoading: boolean;
  columns: Array<Column<T>>;
  tablePageSize?: number;
  getCellProps?: (p: Cell<T, any>) => any;
  getRowProps?: (p: Row<T>) => any;
  getHeaderProps?: (p: HeaderGroup<T>) => any;
  getColumnProps?: (p: Column<T>) => any;
  /**
   * Placeholder für die Suchbox
   *
   * @type {string}
   * @memberof Props
   */
  globalSearchPlaceholder?: string;
  initialSorting?: Array<SortingRule<T>>;
  initialFilter?: Filters<T>;
  disableSearchBox?: boolean;
  disableSorting?: boolean;
  /**
   * Bezeichnung für die Elemente, die in der Tabelle angezeigt werden. Wird unterhalb der Tabelle als "Anzahl [Bezeichnung]: xxx" angezeigt. Wenn keine Bezeichnung angegeben wird, dann wird "Einträge" verwendet.
   *
   * @type {string}
   * @memberof Props
   */
  contentElementName?: string;
  /**
   * Sollen Zeilen ausgewählt werden können? Wenn ja, dann wir eine erste Zeile mit einer Checkbox der Tabelle hinzugefügt
   *
   * @default false
   * @type {boolean}
   * @memberof Props
   */
  enableRowSelection?: boolean;
  /**
   * Callback, der aufgerufen wird, wenn eine Zeile ausgewählt wurde
   *
   * @type {(rows: Array<T>)}
   * @memberof Props
   */
  onRowSelect?: (rows: Array<T>) => void;
  /**
   * Funktion, um eine eigene (primär) ID für die einzelnen Tabellenzeilen zu vergeben
   *
   * @memberof Props
   */
  customRowId?: (
    originalRow: T,
    relativeIndex: number,
    parent?: Row<T> | undefined
  ) => string;
  /**
   * Initial ausgewählte Zeilen
   *
   * @type {Record<IdType<T>, boolean>}
   * @memberof Props
   */
  initialSelectedRowIds?: Record<IdType<T>, boolean>;
  /**
   * Erlaubt das Deaktivieren von AutoReset der internen States der Tabelle
   *
   * @description siehe https://react-table.tanstack.com/docs/faq#how-do-i-stop-my-table-state-from-automatically-resetting-when-my-data-changes
   * @type {boolean}
   * @memberof Props
   */
  disableAutoReset?: boolean;
}

// Create a default prop getter
const defaultPropGetter = () => ({});

interface GlobalFilterProps {
  globalFilter: string;
  setGlobalFilter: (p: string) => void;
  searchPlaceholder?: string;
}

function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  searchPlaceholder,
}: GlobalFilterProps) {
  const [filter, setFilter] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="input-group  w-50">
      <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon1">
          <BsSearch />
        </span>
      </div>
      <input
        placeholder={searchPlaceholder}
        className="form-control"
        value={filter || ""}
        onChange={(e) => {
          setFilter(e.target.value);
          onChange(e.target.value);
        }}
      />
    </div>
  );
}

const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  TableToggleAllRowsSelectedProps
>(({ indeterminate, ...rest }: TableToggleAllRowsSelectedProps, ref) => {
  const defaultRef = React.useRef(null);
  const resolvedRef = useCombinedRefs(ref, defaultRef);

  React.useEffect(() => {
    if (resolvedRef?.current) resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      {/* // TODO: Custom Checkbox verwenden */}
      <input type="checkbox" ref={resolvedRef} {...rest} />
      {/* <Form.Check type="checkbox" ref={resolvedRef} {...rest} custom /> */}
    </>
  );
});

export function DataTableAdvanced<T extends object>({
  data,
  isLoading,
  columns,
  tablePageSize,
  getCellProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  globalSearchPlaceholder,
  initialSorting,
  initialFilter,
  disableSearchBox,
  disableSorting,
  contentElementName = "Einträge",
  enableRowSelection = false,
  onRowSelect,
  customRowId,
  initialSelectedRowIds,
  disableAutoReset,
}: Props<T>): ReactElement {
  const myData = useMemo(() => data ?? [], [data]);
  const myColumns = useMemo(() => columns, [columns]);

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: FilterProps<T>) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      disableFilters: true,
      disableGlobalFilter: false,
    }),
    []
  );

  const tableOptions: TableOptions<T> = {
    columns: myColumns,
    data: myData,
    defaultColumn,
    initialState: {
      pageIndex: 0,
      pageSize: tablePageSize ?? 10,
      sortBy: initialSorting ?? [],
      filters: initialFilter ?? [],
      selectedRowIds:
        initialSelectedRowIds ?? ({} as Record<IdType<T>, boolean>),
    },
  };
  if (customRowId) tableOptions.getRowId = customRowId;

  tableOptions.autoResetExpanded = !disableAutoReset;
  tableOptions.autoResetFilters = !disableAutoReset;
  tableOptions.autoResetGlobalFilter = !disableAutoReset;
  tableOptions.autoResetGroupBy = !disableAutoReset;
  tableOptions.autoResetHiddenColumns = !disableAutoReset;
  tableOptions.autoResetPage = !disableAutoReset;
  tableOptions.autoResetRowState = !disableAutoReset;
  tableOptions.autoResetSelectedRows = !disableAutoReset;
  tableOptions.autoResetSortBy = !disableAutoReset;

  const plugins: PluginHook<T>[] = [useFilters, useGlobalFilter];
  if (!disableSorting) plugins.push(useSortBy);
  plugins.push(usePagination);
  if (enableRowSelection) {
    plugins.push(useRowSelect);
    plugins.push((hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // eslint-disable-next-line react/display-name
          Header: ({
            getToggleAllRowsSelectedProps,
          }: React.PropsWithChildren<HeaderProps<T>>) => (
            <div>
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps({
                  title: "Alle Zeilen auswählen/abwählen",
                })}
              />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          // eslint-disable-next-line react/display-name
          Cell: ({ row }: CellProps<ColumnInstance<T>>) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps({
                  title: "Zeile auswählen/abwählen",
                })}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    });
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page,
    prepareRow,
    //paging
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    visibleColumns,
    selectedFlatRows,
  } = useTable<T>(tableOptions, ...plugins);

  React.useEffect(() => {
    onRowSelect && onRowSelect(selectedFlatRows.map((x) => x.original));
  }, [onRowSelect, selectedFlatRows]);

  // Loading & No-Data
  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    );

  if (!isLoading && data?.length === 0)
    return <Container>Keine {contentElementName} vorhanden!</Container>;

  if (data && data.length > 0) {
    // Paging
    const pagingItem: Array<ReactElement> = [];
    // do we need paging at all?
    if (data.length > pageSize) {
      // we want to have 2 direct page-links on either side
      const startIndex = Math.max(pageIndex - 2, 0);
      const stopIndex = Math.min(pageIndex + 3, pageOptions.length);
      // if the left-most page-link is greater than 0, we need to add ellipsis
      if (startIndex > 0)
        pagingItem.push(<Pagination.Ellipsis key="page-less" disabled />);
      // add page-links
      for (let index = startIndex; index < stopIndex; index++) {
        pagingItem.push(
          <Pagination.Item
            key={`page-${index}`}
            active={index === pageIndex}
            onClick={() => gotoPage(index)}
          >
            {index + 1}
          </Pagination.Item>
        );
      }
      // if the right-most page-link is less than the number of pages, we need to add ellipsis
      if (stopIndex < pageOptions.length)
        pagingItem.push(<Pagination.Ellipsis key="page-more" disabled />);
    }

    const tableClasses = classNames(["mt-3 ", styles.table, " table-striped"]);
    return (
      // we need to disable some eslint-rules, becauses are being added by get[...]Props-functions
      <Fragment>
        <Table {...getTableProps({ className: tableClasses })} borderless hover>
          <thead>
            {!disableSearchBox && (
              <tr role="row">
                <th colSpan={visibleColumns.length}>
                  <GlobalFilter
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    searchPlaceholder={globalSearchPlaceholder}
                  />
                </th>
              </tr>
            )}

            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((headerColumn) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    style={{ verticalAlign: "top" }}
                    {...headerColumn.getHeaderProps([
                      getColumnProps(headerColumn),
                      getHeaderProps(headerColumn),
                    ])}
                  >
                    {disableSorting ? (
                      headerColumn.render("Header")
                    ) : (
                      <span
                        className="d-flex"
                        {...headerColumn.getSortByToggleProps()}
                      >
                        <span className="mr-1">
                          {headerColumn.render("Header")}
                        </span>
                        <span>
                          {headerColumn.canSort ? (
                            headerColumn.isSorted ? (
                              headerColumn.isSortedDesc ? (
                                <BsArrowDown className="text-primary" />
                              ) : (
                                <BsArrowUp className="text-primary" />
                              )
                            ) : (
                              <BsArrowDownUp color="grey" />
                            )
                          ) : null}
                          {headerColumn.canFilter &&
                            !!headerColumn.filterValue && (
                              <BsFunnelFill className="text-primary ml-1" />
                            )}
                        </span>
                      </span>
                    )}

                    {headerColumn.canFilter ? (
                      <div className="d-flex justify-content-center">
                        {headerColumn.render("Filter")}
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="shadow" {...getTableBodyProps()}>
            {
              // Loop over the table rows of the current page
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                const tableRowProps = row.getRowProps(getRowProps(row));
                tableRowProps.className = classNames(
                  // { [styles.row_highlighted]: row.isSelected  },
                  { row_highlighted: row.isSelected },
                  tableRowProps.className
                );
                return (
                  // Apply the row props
                  // eslint-disable-next-line react/jsx-key
                  <tr {...tableRowProps}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            {...cell.getCellProps([
                              getColumnProps(cell.column),
                              getCellProps(cell),
                            ])}
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
        <div className="d-flex justify-content-between">
          <div>
            {pagingItem.length > 0 && (
              <Pagination>
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />

                {pagingItem.map((index) => index)}

                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            )}
          </div>
          <div>
            Anzahl {contentElementName}: {data.length}
          </div>
        </div>
      </Fragment>
    );
  }

  return <></>;
}
