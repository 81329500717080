import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { IconType } from "react-icons";
import { BsArrowRight } from "react-icons/bs";
import classNames from "classnames";

export interface ActionButtonProps extends ButtonProps {
  /**
   * Beschriftung des Buttons. Wenn keine Beschriftung angegeben wird, dann wird "weiter" verwendet.
   */
  caption?: string;
  /**
   * Optionales Icon, welches auf dem Button angezeigt wird. Wird kein Icon angegeben wird ein Pfeil nach rechst als Icon verwendet.
   */
  icon?: IconType;
  /**
   * Soll das Icon links oder rechts vom Text angezeigt werden? Default ist "links"
   */
  iconSide?: "right" | "left";
}

/**
 * Button für Aktionen
 */
export const ActionButton: React.FC<ActionButtonProps> = ({
  caption = "Weiter",
  icon: IconComponent = BsArrowRight,
  iconSide = "left",
  className,
  ...props
}: ActionButtonProps) => {
  const iconWithCaption =
    iconSide === "left" ? (
      <>
        <IconComponent className="text-primary" /> {caption}
      </>
    ) : (
      <>
        {caption} <IconComponent className="text-primary" />
      </>
    );
  return (
    <Button
      variant="secondary"
      className={classNames("", className)}
      {...props}
    >
      {iconWithCaption}
    </Button>
  );
};
