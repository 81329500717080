import React from "react";

export interface FormSectionProps {
  /**
   * Titel des Abschnitts
   */
  title: string;
  /**
   * Soll keine Trennlinie oberhalb des Abschnitts angezeigt werden?
   */
  noDivider?: boolean;
}

/**
 * Abschnitt in einem größeren Formular
 */
export const FormSection: React.FC<FormSectionProps> = ({
  title,
  noDivider = false,
  children,
}: React.PropsWithChildren<FormSectionProps>) => {
  return (
    <>
      {!noDivider ? <hr className="border border-primary bg-primary" /> : null}
      <h5>{title}</h5>
      {children}
    </>
  );
};
